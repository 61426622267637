.GameMain {
    padding: 100px 20px 0;
}

.hpTriviaBtn, .blueGradient {
    background: linear-gradient(336deg, rgba(0,0,255,.8), rgba(0,0,255,0) 70.71%);
    margin: 10px 20px;
}

.no-flex {
    display: block;
}

.text-center .row {
    display: block;
}

@media (max-width: 575.98px) {
    .btn-group {
        display: block;
    }
    .btn-group .btn {
        border-radius: 0;
        font-size: 12px;
        margin-left: auto;
        margin-right: auto;
    }
}