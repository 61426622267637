.content-center {
    margin: 0 auto;
}

.text-center .door-row {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
}

.left-door {
    margin-left: auto;
}

.right-door {
    margin-right: auto;
}

.bot-list {
    margin-left: auto;
    margin-right: auto;
}

.bot-list li {
    font-size: 1.1em;
    font-weight: bold;
}

.bot-button {
    margin-left: auto;
    margin-right: auto;
}

.bot-img {
    margin: 0 10px;
}

@media (max-width: 468px) {
    .bot-img {
        width: 90%;
    }
    .left-door, .right-door {
        margin-left: 0;
        margin-right: 0;
    }
    .all-door {
        width: 33%;
    }
    .bot-list li {
        font-size: .65em;
    }
}

